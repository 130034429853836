<template>
  <div class="dashboard">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>爱答教育</el-breadcrumb-item>
      <el-breadcrumb-item>首页</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <div v-for="(o, index) in infoList" :key="index" class="text item">
          {{ o }}
        </div>
      </el-card>
      <el-card class="box-card" v-if="hasOperationAuth">
        <div slot="header" class="clearfix">
          <span>引流数据</span>
        </div>
        <el-table :data="marketLog" style="width: 100%">
          <el-table-column prop="day" label="日期"> </el-table-column>
          <el-table-column prop="cnt" label="pv"> </el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>科目id</span>
        </div>
        <el-table :data="onlineSubjectCourse" style="width: 100%">
          <el-table-column prop="id" label="id"> </el-table-column>
          <el-table-column prop="title" label="科目名称"> </el-table-column>
          <el-table-column prop="prefix" label="coursePrefix"> </el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card" v-if="hasOperationAuth">
        <div slot="header" class="clearfix">
          <span>查验证码</span>
        </div>
        <el-link
          type="primary"
          href="http://cms.kaiweitalk.cn/api/itedu/v1/aida-user/sms/get?phone=15120094709"
          target="_blank"
          >查验证码</el-link
        >
      </el-card>
    </div>

    <div class="ops">
      <el-button type="danger" size="small" @click="onLogout">安全退出</el-button>
    </div>
  </div>
</template>

<script>
import { loginApi } from "@/api/loginApi.js";
import { tikuApi } from "@/api/tikuApi.js";
import { marketLogApi } from "@/api/marketLogApi.js";
import { epochToDay } from "@/util/TimeUtil";
export default {
  data() {
    return {
      infoList: [],
      marketLog: [],
      onlineSubjectCourse: [],
      hasOperationAuth: false,
      hasUserInfoAuth: false,
    };
  },
  methods: {
    onLogout() {
      loginApi.logout().then((ret) => {
        if (ret.code == 0) {
          this.$router.push({
            name: "login",
          });
        }
      });
    },
    onMarketLogApi() {
      const endTime = Date.now();
      const beginTime = endTime - 7 * 86400 * 1000;
      const beginDay = epochToDay(beginTime);
      const endDay = epochToDay(endTime);
      console.log("beginDay", beginDay, "endDay", endDay);
      marketLogApi.briefReport(beginDay, endDay).then((ret) => {
        if (ret) {
          this.marketLog = ret;
        }
      });
    },
    onTikuApi() {
      tikuApi.allTikuSubjectCourse().then((ret) => {
        if (ret.code == 0 && ret.data) {
          this.onlineSubjectCourse = ret.data;
        }
      });
    },
  },
  mounted() {
    loginApi.getUser().then((ret) => {
      if (ret.code == 0) {
        this.infoList = [ret.data.userPhone];
        loginApi.authList().then((ret) => {
          if (ret.code == 0) {
            this.infoList = [...this.infoList, ...ret.data];
            console.log("..", this.infoList);

            this.hasOperationAuth = this.infoList.filter((x) => x == "operation-r" || x == "root").length > 0;
            this.hasUserInfoAuth = this.infoList.filter((x) => x == "userinfo-r" || x == "root").length > 0;
          }
        });
      }
    });

    this.onMarketLogApi();
    this.onTikuApi();
  },
};
</script>

<style lang="less" scoped>
.dashboard {
  width: 100%;
  .table {
    width: 100%;
  }
  .box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .box-card {
      margin: 20px;
      width: 360px;
    }
  }
}

.ops {
  margin: 40px;
  display: flex;
}
</style>

import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const briefReport = (fromDay, endDay) => {
  return ajax.get(
    `${base}/api/itedu/v1/da/market-log/brief-report?fromDay=${fromDay}&endDay=${endDay}&format=json`
  );
};

export const marketLogApi = {
  briefReport: briefReport
};
